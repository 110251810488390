import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';

const Header = () => (
  <header className="sticky top-0 bg-white shadow" style={{ zIndex: 1 }}>
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <a href="/">#30DEVSTORIES</a>
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#people">
          Uczestnicy
        </AnchorLink>
        <AnchorLink className="px-4 font-bold" href="#join">
          Dopisz się
        </AnchorLink>
        <AnchorLink className="px-4" href="#history">
          Historia
        </AnchorLink>
      </div>
    </div>
  </header>
);

export default Header;
