import React from 'react';
import {Helmet} from 'react-helmet'

import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import people from '../people.json';
import adrian from './adrian.jpg'
import krzysztof from './krzysztof.jpg'
import live from './live.png'

const INSTA_URL = `https://www.instagram.com/`

function Avatar({ username }) {
  const [picUrl, setUrl] = React.useState(null)

  React.useEffect(() => {
    const fetchImage = async () => {
      try {
        const url = `${INSTA_URL}${username}/?__a=1`
        const response = await window.fetch(url)
        const jsonResponse = await response.json()
        setUrl(jsonResponse.graphql.user.profile_pic_url)
      } catch(error) {
        setUrl('')
        console.log('Oh no 😱', error)
      }
    }

    fetchImage()
  }, [username])

  if (!picUrl) {
    return null
  }

  return (
    <img src={picUrl} />
  )
}

export default () => {
  const formPosted = typeof window !== 'undefined' && window.location.href.includes('?ok=true')

  return (
    <Layout>
      <Helmet
        htmlAttributes={{
          lang: 'pl',
        }}
      >
        <title>#30DEVSTORIES</title>
      </Helmet>
      <section className="pt-20 md:pt-40">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/2">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
              #30DEVSTORIES
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              Inicjatywa polegająca na 30 dniowym wyzwaniu podczas, którego co
              dzień publikujesz <strong>InstaStory</strong> na temat związany z programowaniem.
            </p>
            <p className="mt-6 mb-6">
              <a href="https://www.instagram.com/explore/tags/30devstories/" className="py-3 px-5 bg-primary rounded text-white">#30devstories na Instagramie</a>
            </p>
          </div>
          <div className="lg:w-1/2">
            <div style={{ paddingTop: '56.25%', position: 'relative' }}>
              <iframe
                src="https://player.vimeo.com/video/425769826"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              />
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </section>

      <section className="py-20 lg:pt-40">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">#30devstories live</h2>
          <div className="w-1/2 mx-auto text-center">
            <a href="https://www.facebook.com/events/347557576229655" target="_blank">
              <img className="inline-block" src={live} />
            </a>
          </div>
        </div>
      </section>

      <section id="people" className="py-20 lg:pb-40 lg:pt-48">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">Uczestnicy</h2>
          <p>Cała inicjatywa polega na tym, aby zebrać ciekawe osoby, które chcą przedstawiać swoją perspektywę na <i>Software Development</i>.</p>
          <p>Nie ważne czy programujesz od roku czy od lat piętnastu. Wszyscy dostrzegamy i odbieramy pewne aspekty nieco inaczej. Warto poznawać poglądy innych, aby rozwijać swoje umiejętności.</p>
          <p>Siła tego wyzwania tkwi w osobach, które do niego dołączyły ❤️</p>
          <p className="mb-6">A jest nas sporo! Koniecznie sprawdź tą długą listę ⤵️⤵️</p>
          {/* <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12 flex-wrap"> */}
          <div className="flex flex-wrap">
            {people.map(person => (
              <div className="w-full md:w-1/2 lg:w-1/3 px-3">
                <Card className="mb-8 px-3">
                  <p className="font-semibold text-xl pb-2">
                    <a href={INSTA_URL+person.login}>
                      @{person.login}
                    </a>
                  </p>
                  <p className="flex content-center justify-center">
                    <a href={INSTA_URL+person.login}>
                      <Avatar username={person.login} />
                    </a>
                  </p>
                  <p className="mt-4">
                    {person.description}
                  </p>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="join">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">DOŁĄCZ DO LISTY</h2>
          <p>Publikujesz swoje #30devstories? Daj nam koniecznie znać, abyś znalazł się na naszej liście.</p>

          {formPosted ? 'Twoje zgłoszenie czeka na zatwierdzenie, dzięki!' : (
            <div className="bg-white rounded-t-lg overflow-hidden p-4 flex justify-center">
              <form
                className="w-full max-w-sm"
                name="join"
                method="POST"
                action="/?ok=true"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="bot-field" />
                <div
                  className="md:flex md:items-center mb-6"
                >
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="Nazwa użytkownika bez @ (wymagane)"
                    aria-label="Username"
                    name="username"
                    required
                  />
                </div>
                <div
                  className="md:flex md:items-center mb-6"
                >
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="Kilka słów o sobie"
                    maxLength={140}
                    aria-label="Description"
                    name="description"
                  />
                </div>
                <div
                  className="md:flex md:items-center mb-6 justify-center"
                >
                  <Button
                    size="xl"
                    type="submit"
                  >
                    Dopisz mnie do listy!
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
      </section>

      <section className="container mx-auto my-24 py-24 bg-gray-200 rounded-lg text-center" id="history">
        <h3 className="text-5xl font-semibold">Ale jak to się zaczęło?</h3>
        <p className="p-3 mt-8 text-xl font-light">
            To była szybka rozmowa na privkach. Adrian chciał rozpocząć nagrywanie InstaStory ale od razu rzucając sobie małe wyzwanie. Polegało ono na tym aby przez 30 dni gadać dzień w dzień. Krzysztof odpowiedział bardzo krótko: „dołączam, w grupie raźniej”.
        </p>
        <p className="p-3 mt-8 text-xl font-light">Chwilę później, powstał odpowiedni #hasztag i zgadaliśmy się, że startujemy oznaczając przy okazji dwie kolejne osoby!</p>
        <p className="p-3 mt-8 text-xl font-light">Pomysł podchwyciło bardzo wiele osób z polskiej społeczności IT, zgromadzonej na Instagramie 🙂</p>
        <div className="flex flex-wrap m-4">
          <div className="w-full md:w-1/2 px-3 mx-auto m-4">
            <img className="inline-block" src={adrian} />
            <p className="p-3 mt-8">
              Na co dzień Software Engineer, jeszcze programujący CTO w firmie <strong>Emphie Solutions</strong>.<br />
              Fascynat programowania, architektury, metodyk zwinnych i dobrych praktyk w szerokim ujęciu.<br />
              Polyglot Programer kochający poznawać nowe języki jednocześnie wykorzystując ich najlepsze strony.<br />
              Założyciel inicjatywy <a href="https://devenv.pl/">DevEnv</a>, współautor <a href="https://devenv.pl/podcast">podcastu DevEnv</a> oraz kanału <a href="">YouTube DevEnv</a>.
            </p>
          </div>
          <div className="w-full md:w-1/2 px-3 mx-auto m-4">
            <img className="inline-block" src={krzysztof} />
            <p className="p-3 mt-8">
              Full-stack web developer, twórca bloga i kanału na YouTube <a href="https://skutecznyprogramista.pl/">skutecznyprogramista.pl</a>.<br />
              Wierzy w pragmatyczne podejście do wytwarzania oprogramowania, gdzie proste rozwiązania, dobra komunikacja i ciągły rozwój w małych inkrementach to klucz do sukcesu.<br />
              Dotychczas zdobytą wiedzę weryfikuje przez dzielenie się nią na konferencjach, meetupach i warsztatach.<br />
              Chętnie podyskutuje z Tobą o tym jak robić dobry software, jak zwiększać swoją wartość w oczach klientów i pracodawców oraz jak pułapki ludzkiego mózgu wpływają na nasze codzienne życie.
            </p>
          </div>
        </div>
      </section>

    </Layout>
  )
}
